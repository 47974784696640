import React, { useState } from "react";

const MailchimpForm = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  return (
    <div className="flex justify-center items-center min-h-screen bg-black text-white text-4xl px-10">
      <style type="text/css">
        {`
          body {
            margin: 0;
            padding: 0;
          }

          #mc_embed_signup {
            // background: #f7f7f7;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            width: 600px;
            padding: 20px;
            border-radius: 10px;
            color: #000; /* Set text color to black */
          }
          #mc_embed_signup h2 {
            color: #000;
          }

          .mc-field-group input[type="email"],
          .mc-field-group input[type="text"] {
            width: calc(100% - 22px);
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid #333; /* Set border color to bg-zinc-900 */
            border-radius: 5px;
            font-size: 14px;
            color: #fff;
            background-color: #000;
          }

          #mc-embedded-subscribe {
            background-color: #333; /* Darker shade of black for button */
            color: #fff;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
          }

          .refferal_badge {
            display: none;
          }

          
          #mc_embed_signup h2,
          .mc-field-group label,
          .mc-field-group input[type="email"],
          .mc-field-group input[type="text"] {
            color: #fff; /* Set text color to white */
          }
          /* Default styles for all devices */

          body {
              margin: 0;
              padding: 0;
              font-family: Arial, sans-serif;
              background-color: black;
              color: white;
          }

          #mc_embed_signup {
              width: 100%;
              max-width: 600px;
              padding: 20px;
              border-radius: 10px;
              background-color: #333;
          }

          .mc-field-group input[type="email"],
          .mc-field-group input[type="text"] {
              width: 100%;
              padding: 10px;
              margin-bottom: 10px;
              border: 1px solid #666;
              border-radius: 5px;
              font-size: 14px;
              color: #fff;
              background-color: #000;
          }

          #mc-embedded-subscribe {
              width: 100%;
              background-color: #666;
              color: #fff;
              border: none;
              padding: 10px 20px;
              border-radius: 5px;
              cursor: pointer;
              font-size: 16px;
          }

          /* Media query for larger devices */
          @media (min-width: 768px) {
              #mc_embed_signup {
                  padding:10px;
                  // width: 70%;
              }
          }

        `}
      </style>
      <div id="mc_embed_signup" className="bg-zinc-900 p-20 rounded-lg">
        <form
          action="https://omshingare.us8.list-manage.com/subscribe/post?u=d0d611e70fb0fd8565c2f8a1a&amp;id=b186402277&amp;f_id=009774e0f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll" className="pt-8">
            <h2 className="text-2xl antialiased font-bold text-white">
              WANT TO GET MORE AMAZING BLOGS ?
            </h2>

            <div className="mc-field-group pt-5">
              <label htmlFor="mce-EMAIL">
                Email Address <span className="asterisk">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                value={email}
                required
                onChange={handleEmailChange}
              />
              <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-FNAME">
                First Name <span className="asterisk">*</span>
              </label>
              <input
                type="text"
                name="FNAME"
                id="mce-FNAME"
                required
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-LNAME">
                Last Name <span className="asterisk">*</span>
              </label>
              <input
                type="text"
                name="LNAME"
                required
                id="mce-LNAME"
                value={lastName}
                onChange={handleLastNameChange}
              />
            </div>
            <div className="clear foot">
              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
                value="Subscribe"
              />
            </div>
          </div>
        </form>
      </div>
      <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      ></script>
      <script type="text/javascript">
        {`
          (function($) {
            window.fnames = new Array();
            window.ftypes = new Array();
            fnames[0]='EMAIL';
            ftypes[0]='email';
            fnames[1]='FNAME';
            ftypes[1]='text';
            fnames[2]='LNAME';
            ftypes[2]='text';
            fnames[3]='ADDRESS';
            ftypes[3]='address';
            fnames[4]='PHONE';
            ftypes[4]='phone';
            fnames[5]='BIRTHDAY';
            ftypes[5]='birthday';
          }(jQuery));
          var $mcj = jQuery.noConflict(true);
        `}
      </script>
    </div>
  );
};

export default MailchimpForm;
