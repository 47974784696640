import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Navbar, MailchimpForm} from './Components'
const App = () => {
  return (
    <Router>
      <Navbar/>
      
      <Routes>
        <Route path="*" element={<MailchimpForm/>}/>
      </Routes>

    </Router>
  );
};

export default App;