import React from "react";
import "../index.css"; // Import your CSS file

const Navbar = () => {
  return (
    <nav className="bg-zinc-900 text-white p-4">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
        <div className="flex items-center space-x-4 mb-4 lg:mb-0">
          <a href="https://omshingare.me" className="no-underline hover:glow-neon">
            Portfolio
          </a>
          <a href="https://omshingare.me/displayblogs" className="no-underline hover:glow-neon">
            Blog
          </a>
        </div>

        <div className="flex items-center space-x-4 font-bold">
          <a
            href="mailto:omshingare@duck.com"
            className="btn btnmail text-slate-50 rounded"
          >
            Mail Me 😁&gt;
          </a>
          <a href="https://omshingare.me" className="btn btn-name rounded">
            Om Shingare &gt;
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
